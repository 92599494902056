<template>
  <div class="kyc-check">
    <b-row align-h="around">
      <b-col
        md="6"
        class="kyc-info-box"
      >
        <div class="kyc-information">
          <ul>
            <li>
              <img
                src="@/assets/images/usercenter/check_id.png"
                alt="li"
              >
              <div class="kyc-key-value">
                <div class="kyc-check-key">
                  User ID
                </div>
                <div class="kyc-check-value">
                  {{ userId }}
                </div>
              </div>
            </li>
            <li>
              <img
                src="@/assets/images/usercenter/check_head.png"
                alt="li"
              >
              <div class="kyc-key-value">
                <div class="kyc-check-key">
                  Name
                </div>
                <div class="kyc-check-value">
                  {{ name }}
                </div>
              </div>
            </li>
            <li>
              <img
                src="@/assets/images/usercenter/check_cpf.png"
                alt="li"
              >
              <div class="kyc-key-value">
                <div class="kyc-check-key">
                  {{ documentType }}
                </div>
                <div class="kyc-check-value">
                  {{ documentId }}
                </div>
              </div>
            </li>
            <li>
              <img
                src="@/assets/images/usercenter/check_location.png"
                alt="li"
              >
              <div class="kyc-key-value">
                <div class="kyc-check-key">
                  Address
                </div>
                <div class="kyc-check-value">
                  {{ street }}
                </div>
                <div class="kyc-check-value">
                  {{ city }}
                </div>
              </div>
            </li>
            <li>
              <img
                src="@/assets/images/usercenter/check_dollar.png"
                alt="li"
              >
              <div class="kyc-key-value">
                <div class="kyc-check-key">
                  Source of funds
                </div>
                <div class="kyc-check-value">
                  {{ salary }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </b-col>
      <b-col
        md="6"
        class="kyc-info-box"
      >
        <div
          class="kyc-image-box pb-2"
          @mouseenter="idDocumentStatus=true"
          @mouseleave="idDocumentStatus=false"
        >
          <div class="kyc-info-content">
            <div
              v-if="idDocument"
              class="kyc-info-image"
              @click="clickPictrue"
            >
              <img
                :src="idDocument"
                alt="id document"
                class="kyc-id-image"
              >
            </div>
          </div>
          <div class="kyc-info-content">
            <div
              v-if="idSelfie"
              class="kyc-info-image"
              @click="clickPictrue"
            >
              <img
                :src="idSelfie"
                alt="id document"
                class="kyc-id-image"
              >
            </div>
          </div>
          <div
            v-if="idDocument?(checkKycStatus!==''?true:idDocumentStatus):false"
            class="image-box-bg"
          />
          <div
            v-if="idDocument?(checkKycStatus!==''?true:idDocumentStatus):false"
            class="image-box-buttons"
          >
            <img
              src="@/assets/images/usercenter/plus.png"
              alt="plus"
              class="image-agree-plus"
              :data-url="idDocument"
              @click="showPicture"
            >
            <img
              src="@/assets/images/usercenter/plus.png"
              alt="plus"
              class="image-refuse-plus"
              :data-url="idSelfie"
              @click="showPicture"
            >
            <div class="image-box-agree">
              <img
                :src="(checkKycStatus!=='' && checkKycStatus) ? require('@/assets/images/usercenter/agree-selected.png'):require('@/assets/images/usercenter/agree.png')"
                alt="✅"
                @click="()=>{if(`${checkKycStatus}`=='true'){checkKycStatus=''}else{checkKycStatus=true}}"
              >
              <img
                :src="(checkKycStatus!=='' && !checkKycStatus) ? require('@/assets/images/usercenter/refuse-selected.png'):require('@/assets/images/usercenter/refuse.png')"
                alt="❎"
                @click="()=>{if(`${checkKycStatus}`=='false'){checkKycStatus=''}else{checkKycStatus=false}}"
              >
            </div>
          </div>
        </div>
        <div class="kyc-image-box pb-2">
          <div
            class="kyc-info-content"
            @mouseenter="addressPicStatus=true"
            @mouseleave="addressPicStatus=false"
          >
            <div
              v-if="addressPic"
              class="kyc-info-image"
              @click="clickPictrue"
            >
              <img
                :src="addressPic"
                alt="id selfie"
                class="kyc-id-image"
              >
            </div>
            <div
              v-if="addressPic?(checkAddressStatus!==''?true:addressPicStatus):false"
              class="image-box-bg"
            />
            <div
              v-if="addressPic?(checkAddressStatus!==''?true:addressPicStatus):false"
              class="image-box-buttons"
            >
              <img
                src="@/assets/images/usercenter/plus.png"
                alt="plus"
                class="image-agree-plus"
                :data-url="addressPic"
                @click="showPicture"
              >
              <div class="image-box-agree">
                <img
                  :src="(checkAddressStatus!=='' && checkAddressStatus) ? require('@/assets/images/usercenter/agree-selected.png'):require('@/assets/images/usercenter/agree.png')"
                  alt="✅"
                  @click="()=>{if(`${checkAddressStatus}`=='true'){checkAddressStatus=''}else{checkAddressStatus=true}}"
                >
                <img
                  :src="(checkAddressStatus!=='' && !checkAddressStatus) ? require('@/assets/images/usercenter/refuse-selected.png'):require('@/assets/images/usercenter/refuse.png')"
                  alt="❎"
                  @click="()=>{if(`${checkAddressStatus}`=='false'){checkAddressStatus=''}else{checkAddressStatus=false}}"
                >
              </div>
            </div>
          </div>
          <div
            class="kyc-info-content"
            @mouseenter="fundSourcePicStatus=true"
            @mouseleave="fundSourcePicStatus=false"
          >
            <div
              v-if="fundSourcePic"
              class="kyc-info-image"
              @click="clickPictrue"
            >
              <img
                :src="fundSourcePic"
                alt="id selfie"
                class="kyc-id-image"
              >
            </div>
            <div
              v-if="fundSourcePic?(checkFundStatus!==''?true:fundSourcePicStatus):false"
              class="image-box-bg"
            />
            <div
              v-if="fundSourcePic?(checkFundStatus!==''?true:fundSourcePicStatus):false"
              class="image-box-buttons"
            >
              <img
                src="@/assets/images/usercenter/plus.png"
                alt="plus"
                class="image-agree-plus"
                :data-url="fundSourcePic"
                @click="showPicture"
              >
              <div class="image-box-agree">
                <img
                  :src="(checkFundStatus!=='' && checkFundStatus) ? require('@/assets/images/usercenter/agree-selected.png'):require('@/assets/images/usercenter/agree.png')"
                  alt="✅"
                  @click="()=>{if(`${checkFundStatus}`=='true'){checkFundStatus=''}else{checkFundStatus=true}}"
                >
                <img
                  :src="(checkFundStatus!=='' && !checkFundStatus) ? require('@/assets/images/usercenter/refuse-selected.png'):require('@/assets/images/usercenter/refuse.png')"
                  alt="❎"
                  @click="()=>{if(`${checkFundStatus}`=='false'){checkFundStatus=''}else{checkFundStatus=false}}"
                >
              </div>
            </div>
          </div>
        </div></b-col>
    </b-row>
    <h3 class="kyc-ensure-title">
      Please ensure:
    </h3>
    <b-row align-h="around">
      <b-col
        md="6"
        class="kyc-ensure-content"
      >
        <p>Both name and CPF match the ID</p>
        <p>The person's name appears in both the ID and selfie pictures</p>
        <p>The address matches that on file</p>
        <p>The source of income is credible</p>
      </b-col>
      <b-col
        md="6"
        class="kyc-status-content"
      >
        <!-- <span>KYC Status</span>
        <v-select
          v-model="checkKycStatus"
          class="check-status-select"
          :options="
            keyStatus === 5 ? checkNoReUploadOptions : checkKycStatusOptions
          "
          :clearable="false"
          :searchable="false"
          :reduce="(val) => val.value"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select> -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="saveCheckStatus"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  defineComponent,
  reactive,
  toRefs,
  getCurrentInstance,
  computed,
  onBeforeMount,
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BPagination,
  BButton,
  BLink,
  BModal,
  VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { showToast } from '@/libs/utils'

export default defineComponent({
  setup() {
    const { proxy } = getCurrentInstance()
    const state = reactive({
      checkKycStatus: '',
      checkAddressStatus: '',
      checkFundStatus: '',
      checkKycStatusOptions: [
        { label: 'Auto-pass', value: 3 },
        { label: 'Verified', value: 4 },
        { label: 'Re-upload request', value: 5 },
        { label: 'Permanently blocked', value: 2 },
      ],
      checkNoReUploadOptions: [
        { label: 'Auto-pass', value: 3 },
        { label: 'Verified', value: 4 },
        { label: 'Permanently blocked', value: 2 },
      ],
      idDocumentStatus: false,
      addressPicStatus: false,
      fundSourcePicStatus: false,
    })
    const {
      checkKycStatus, checkAddressStatus, checkFundStatus, checkKycStatusOptions, checkNoReUploadOptions, idDocumentStatus, addressPicStatus, fundSourcePicStatus,
    } = toRefs(state)
    onBeforeMount(() => {
      if (proxy.keyStatus === 4) {
        state.checkKycStatus = true
      } else if (proxy.keyStatus === 5) {
        state.checkKycStatus = false
      } else {
        state.checkKycStatus = ''
      }
      if (proxy.addressStatus === 4) {
        state.checkAddressStatus = true
      } else if (proxy.addressStatus === 5) {
        state.checkAddressStatus = false
      } else {
        state.checkAddressStatus = ''
      }
      if (proxy.fundSourceStatus === 4) {
        state.checkFundStatus = true
      } else if (proxy.fundSourceStatus === 5) {
        state.checkFundStatus = false
      } else {
        state.checkFundStatus = ''
      }
    })
    const saveCheckStatus = () => {
      proxy.$showLoading()
      const params = {
        user_id: proxy.userId,
        // kyc_status: 0,
        // address_status: 0,
        // fund_source_status: 0,
      }
      if (`${state.checkKycStatus}` === '' && `${state.checkAddressStatus}` === '' && `${state.checkFundStatus}` === '') {
        showToast(proxy, 'warning', 'Please check kyc information')
        proxy.$hideLoading()
        return
      }
      // 4:Verified 5:Re-upload request
      if (`${state.checkKycStatus}` === 'true') {
        params.kyc_status = 4
      } else if (`${state.checkKycStatus}` === 'false') {
        params.kyc_status = 5
      }
      if (`${state.checkAddressStatus}` === 'true') {
        params.address_status = 4
      } else if (`${state.checkAddressStatus}` === 'false') {
        params.address_status = 5
      }
      if (`${state.checkFundStatus}` === 'true') {
        params.fund_source_status = 4
      } else if (`${state.checkFundStatus}` === 'false') {
        params.fund_source_status = 5
      }
      proxy.$http
        .post('/api/v2/admin/user_kyc/check', params)
        .then(response => {
          const { code, message } = response.data
          if (code === 200) {
            proxy.$emit('afterKycCheck')
          } else {
            showToast(proxy, 'warning', message)
          }
          proxy.$hideLoading()
        })
        .catch(error => {
          proxy.$hideLoading()
          showToast(proxy, 'warning', error.message)
        })
    }
    const clickPictrue = e => {
      if (e.target.tagName.toUpperCase() === 'IMG') {
        proxy.$previewPictrue(e.target.src) // 获取到图片的src传递插件就能实现图片预览  非常的便捷灵活
      }
    }
    const showPicture = event => {
      proxy.$previewPictrue(event.target.dataset.url)
    }
    return {
      checkKycStatus,
      checkAddressStatus,
      checkFundStatus,
      checkKycStatusOptions,
      checkNoReUploadOptions,
      saveCheckStatus,
      clickPictrue,
      showPicture,
      idDocumentStatus,
      addressPicStatus,
      fundSourcePicStatus,
    }
  },
  props: {
    userId: {
      type: String,
      default: '',
    },
    idDocument: {
      type: String,
      default: '',
    },
    idSelfie: {
      type: String,
      default: '',
    },
    addressPic: {
      type: String,
      default: '',
    },
    fundSourcePic: {
      type: String,
      default: '',
    },
    keyStatus: {
      type: Number,
      default: 0,
    },
    fundSourceStatus: {
      type: Number,
      default: 0,
    },
    addressStatus: {
      type: Number,
      default: 0,
    },
    name: {
      type: String,
      default: '',
    },
    documentType: {
      type: String,
      default: '',
    },
    documentId: {
      type: String,
      default: '',
    },
    street: {
      type: String,
      default: '',
    },
    city: {
      type: String,
      default: '',
    },
    salary: {
      type: String,
      default: '',
    },
  },
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    BButton,
    BLink,
    BModal,

    vSelect,
  },
  directives: {
    Ripple,
  },
})
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.kyc-check {
  .kyc-info-box {
    background-color: #ffffff;
    position: relative;
    .kyc-image-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      position: relative;
      .kyc-info-content {
        width: 48%;
      }
      .image-box-bg {
        background-color: #000000;
        opacity: 0.4;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 180px;
      }
      .image-box-buttons {
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 180px;
        .image-agree-plus {
          position: absolute;
          bottom: 15px;
          left: 15px;
          width: 30px;
          height: auto;
          cursor: pointer;
        }
        .image-refuse-plus {
          position: absolute;
          bottom: 15px;
          left: 205px;
          width: 30px;
          height: auto;
          cursor: pointer;
        }
        .image-box-agree {
          position: absolute;
          right: 10px;
          top: 10px;
          img {
            width: 35px;
            height: auto;
            cursor: pointer;
          }
          img:nth-of-type(1) {
            margin-right: 10px;
          }
        }
      }
    }
    .plus-icon {
      position: absolute;
      bottom: 10px;
      right: 25px;
      width: 30px;
      height: auto;
      cursor: pointer;
    }
  }
  .kyc-information {
    background-color: #fbfbfd;
    height: 382px;
    ul {
      display: inline-block;
      padding-left: 20px;
      margin-top: 10px;
      li {
        list-style: none;
        display: flex;
        align-items: center;
        margin-top: 25px;
        img {
          width: 40px;
          float: left;
          margin-right: 15px;
        }
        .kyc-key-value {
          .kyc-check-key {
            color: '#dadadc';
          }
          .kyc-check-value {
            color: '#333333';
            font-weight: 500;
          }
        }
      }
    }
  }
  .kyc-info-content {
    background-color: #fbfbfd;
    height: 180px;
    position: relative;
    .kyc-info-image {
      width: 100%;
      height: 100%;
      .kyc-id-image {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
  }
  .kyc-ensure-title {
    margin: 20px 0 10px;
    font-size: 16px;
  }
  .kyc-ensure-content {
    padding-bottom: 30px;
    p {
      margin-bottom: 0;
    }
  }
  .kyc-status-content {
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .check-status-select {
      width: 200px;
      height: 40px;
      .vs__dropdown-toggle {
        height: 40px;
      }
    }
  }
}
</style>
